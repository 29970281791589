import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/home/home';
import EditSet from './components/edit-set/edit-set';
import StudySet from './components/study-set/study-set';
import NotFound from './components/not-found/not-found';
import Landing from './components/landing/landing';
import Blog from './components/blog/blog';
import ImportSet from './components/import-set/import-set';
import TranslationPractice from './components/translation-practice/translation-practice';
import Header from './components/shared/header';
import About from './components/about/about';
import MobilePrivacyPolicy from './components/mobile-privacy-policy/mobile-privacy-policy';
import Needle from './components/needle/needle';

function App() {
  return (
    <div className="App bg-zinc-600 font-roboto text-black">
      <div>
        <BrowserRouter>
          {/* <Header></Header> */}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<Home />} />
            <Route path="edit-set" element={<EditSet />}>
              <Route path=":setName" element={<EditSet />} />
            </Route>
            <Route path="blog" element={<Blog />} >
              <Route path=":blogName" element={<Blog />} /> 
            </Route>
            <Route path="/study-set/:setName" element={<StudySet />} />
            <Route path="/import-set" element={<ImportSet />} />
            <Route path="/translation-practice/:setName" element={<TranslationPractice />} />
            <Route path="/about" element={<About />} />
            <Route path="/mobile-privacy-policy" element={<MobilePrivacyPolicy />} />
            <Route path="/needle" element={<Needle />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
