import moment from "moment";
import { Confidence, SetNameTemplate, SetTemplate } from "../templates/set-template";

export class SetService {

    getSetNames(): SetNameTemplate[] {
        let sets = localStorage.getItem('sets');
        if(sets === null)
            return [];
        return JSON.parse(sets);
    }
    
    getSet(setName: string): SetTemplate[] {
        let set = localStorage.getItem(setName);
        if(set === null)
            return [];
        return JSON.parse(set);
    }
    
    deleteSet(setName: string) {
        let setNames: SetNameTemplate[] = this.getSetNames();
        for(let i = 0; i < setNames.length; i++) {
            if(setNames[i].setName === setName) {
                setNames.splice(i, 1);
                break;
            }
        }
        localStorage.removeItem(setName);
        this.saveSetNames(JSON.stringify(setNames));
    }
    
    saveSetNames(json: string) {
        localStorage.setItem('sets', json);
    }
    
    saveJSON(setName: string, set: SetTemplate[]) {
        console.log("Saving", setName, set);
        let setNames: SetNameTemplate[] = this.getSetNames();
        let foundSet = setNames.find(o => o.setName === setName);
        if(foundSet === undefined) {
          setNames.push({ setName: setName });
        } else {
            foundSet.lastStudied = this.getDateString();
            setNames = setNames.filter(s => s.setName !== setName);
            setNames = [...setNames, foundSet];
        }
        this.saveSetNames(JSON.stringify(setNames));
        localStorage.removeItem(setName);
        localStorage.setItem(setName, JSON.stringify(set));
    }
    
    getDateString(date?: Date): string {
        var dateIn = date ?? new Date();
        var dd = String(dateIn.getDate()).padStart(2, '0');
        var mm = String(dateIn.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = dateIn.getFullYear();
        return yyyy + '/' + mm + '/' + dd;
    }

    addDaysToDate(date: string, daysToAdd: number): string {
        return moment(date).add(daysToAdd, 'days').format('YYYY/MM/DD');
    }
    
    updateCardDate(setName: string, card: SetTemplate, date?: string) {
        let set = this.getSet(setName);
        let index = set.findIndex(e => e.primaryVal === card.primaryVal && e.secondaryVal === card.secondaryVal);
        set[index].oldDate = set[index].dateToStudy;
        set[index].dateToStudy = date;
        this.saveJSON(setName, set);
    }

    getNextDate(confidence: Confidence, currentDate: string, oldDate?: string): string {
        let diffInDays = (oldDate) ? (new Date(currentDate)).getTime() - (new Date(oldDate)).getTime() : 1;
        diffInDays = diffInDays / 86400000;
        if(diffInDays < 1) diffInDays = 1;
        let daysToAdd = 0;
        if(confidence === Confidence.LOW) {
            let divided = Math.floor(diffInDays/3);
            daysToAdd = (divided > 0 && divided <= 3) ? divided : 1;
        } else if(confidence === Confidence.MEDIUM) {
            daysToAdd = diffInDays + Math.floor(diffInDays / 2);
        } else if(confidence === Confidence.HIGH) {
            daysToAdd = Math.ceil(diffInDays * 1.75);
        }
        let date = moment(currentDate).add(daysToAdd, 'days').format('YYYY/MM/DD');
        return date;
    }

    getMaxStudyAmount(): number {
        return 30;
    }

}