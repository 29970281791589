import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SetService } from "../../services/set-service";
import { SetTemplate } from "../../templates/set-template";

interface Row {
    primaryVal: string;
    secondaryVal: string;
}

export default function ImportSet() {
    const [setName, setSetName] = useState<string>('');
    const [setData, setSetData] = useState<string>('');
    const [termSeparator, setTermSeparator] = useState<string>('comma');
    const [cardSeparator, setCardSeparator] = useState<string>('newLine');
    const [placeholder, setPlaceholder] = useState<string>('');

    useEffect(() => {
        function getSeparatorChar(separator: string): string {
            if(separator === 'hyphen') {
                return '-';
            } else if(separator === 'comma') {
                return ',';
            } else if(separator === 'newLine') {
                return '\n';
            } else if(separator === 'semicolon') {
                return ';';
            }
            return '';
        }

        let newPlaceholder = "";
        for(let i = 0; i < 3; i++) {
            newPlaceholder += 'Term' + (i+1) + getSeparatorChar(termSeparator) + 'Definition' + (i+1) + getSeparatorChar(cardSeparator);
        }
        setPlaceholder(newPlaceholder);
    }, [termSeparator, cardSeparator]);

    const separators: Map<String, RegExp> = new Map([
        ['hyphen', RegExp('[-]')],
        ['comma', RegExp('[,]')],
        ['newLine', RegExp('[\r\n|\n|\r]')],
        ['semicolon', RegExp('[;]')]
    ]);

    const setService = useMemo(() => new SetService(), []);
    const navigate = useNavigate();

    function saveSet(passedVals: Row[]) {
        if(passedVals.length !== 0 && setName !== "") {
            let success = false;
            const setData: SetTemplate[] = [];
            passedVals.forEach(vals => {
                if(vals.primaryVal === '' || vals.secondaryVal === '')
                    return;
                try {
                    setData.push(
                        {
                            primaryVal: vals.primaryVal,
                            secondaryVal: vals.secondaryVal,
                            dateToStudy: setService.getDateString()
                        }
                    );
                    success = true;
                } catch(e) {
                    console.error("Failed to save the set from text area:", e);
                }
            });
            if(success) {
                setService.saveJSON(setName, setData);
                navigate("/home");
            }
        }
    }

    function onFormSubmit() {
        const tempVals: Row[] = [];
        let splitCards = setData.split(separators.get(cardSeparator) ?? RegExp('[,]'));
        splitCards.forEach(item => {
            let splitTerms = item.split(separators.get(termSeparator) ?? RegExp('[\r\n|\n|\r]'));
            try {
                tempVals.push({
                    primaryVal: splitTerms[0],
                    secondaryVal: splitTerms[1]
                });
            } catch (e) {
                console.log("Could not split text for item:", item, "with exception:", e);
            }
            
        });
        saveSet(tempVals);
    }

    function exit() {
        setSetName('');
        setSetData('');
        navigate('/home');
    }

    return(
        <div className="w-full h-screen flex flex-col">
            <div className="flex flex-wrap text-4xl my-5 justify-center">
                Set Name:
                <input type="text" onChange={(event) => setSetName(event.target.value)} value={setName} className="w-72 ml-2 rounded bg-slate-700 text-white text-3xl p-1"></input>
            </div>
            <div className="text-2xl mb-1">Select Separators</div>
            <div className="flex flex-wrap mb-5 justify-center drop-shadow-lg">
                <div className="flex flex-wrap sm:mb-0 mb-1">
                    <div className="text-lg mr-2">Between Term</div>
                    <select defaultValue={termSeparator} onChange={(event: any) => {setTermSeparator(event.target.value)}} className="rounded bg-slate-700 p-1">
                        <option value="comma">Commas</option>
                        <option value="hyphen">Hyphens</option>
                    </select>
                </div>
                <div className="ml-5 xs:ml-0 mt-0 xs:mt-1 flex flex-wrap">
                    <div className="text-lg mr-2">Between Cards</div>
                    <select defaultValue={cardSeparator} onChange={(event: any) => {setCardSeparator(event.target.value)}} className="rounded bg-slate-700 p-1">
                        <option value="newLine">New Line</option>
                        <option value="semicolon">Semicolon</option>
                    </select>
                </div>
            </div>
            <div className="flex flex-col mb-5 sm:w-1/2 w-3/4 mx-auto h-1/2">
                <textarea placeholder={placeholder} defaultValue={''} onChange={(event: any) => {setSetData(event.target.value)}} className="resize-none bg-slate-700 rounded text-white sm:text-lg p-1 h-full"></textarea>
            </div>
            <div className="justify-center">
                <button type="button" onClick={exit} className="w-60 m-1 py-2 rounded-lg bg-slate-700 drop-shadow-lg">Exit</button>
                <button type="button" onClick={onFormSubmit} className="w-60 m-1 py-2 rounded-lg bg-slate-700 drop-shadow-lg">Save Set</button>
            </div>
        </div>
    );
}