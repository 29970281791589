export default function MobilePrivacyPolicy() {
    return (
        <div className="w-full h-full flex flex-col p-5 pt-0">
            <div className="text-3xl justify-center my-5">
                Xue Mobile Privacy Policy
            </div>
            <div className="text-xl md:w-1/2 w-10/12 mx-auto">
                Xue Mobile only collects and stores data associated with app usage that is input and saved into the app (which may include, but is not limited to the study sets generated and date of study).
                This data is only retained on the device where Xue Mobile has been installed and is not collected, stored, or used elsewhere by Xue Mobile.
                If you would like to delete this data please delete the app from your device and remove any associated files / storage.
                Xue Mobile contains links to other websites not affiliated with us or our privacy policy. Clicking a 3rd party link will direct you to that party's website.
                This privacy policy may change in the future. You will be notified of this upon opening the Xue Mobile app. We also advise that you keep up to date with the Xue Mobile privacy policy by checking this page periodically. The privacy policy on this page is in effect immediately upon update.
            </div>
        </div>
    )
}