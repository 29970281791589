import needle from "../../assets/needle.png";
import circle from "../../assets/needle-background.png";
import { useEffect, useState } from "react";



function NeedleComp(props: { state: string }) {
    const { state } = props;
    const [val, setVal] = useState<number>(0);
    const [style, setStyles] = useState<any>();
    const [bgColor, setBgColor] = useState<string>('bg-zinc-300');

    useEffect(() => {
        var stylingObject = {
              transformOrigin: "center bottom",
              transform: `rotate(${val.valueOf()}deg)`
        }
        setStyles(stylingObject);
        console.log(val);
        switch(val) {
            case 0: {
                setBgColor('bg-zinc-300 text-gray-600');
                break;
            }
            case 11.25: {
                setBgColor('bg-rose-200 text-gray-600');
                break;
            }
            case 33.75: {
                setBgColor('bg-rose-300 text-gray-600');
                break;
            }
            case 56.25: {
                setBgColor('bg-rose-400 text-gray-200');
                break;
            }
            case 78.75: {
                setBgColor('bg-rose-500 text-gray-200');
                break;
            }
            case -11.25: {
                setBgColor('bg-cyan-200 text-gray-600');
                break;
            }
            case -33.75: {
                setBgColor('bg-cyan-300 text-gray-600');
                break;
            }
            case -56.25: {
                setBgColor('bg-cyan-400 text-gray-200');
                break;
            }
            case -78.75: {
                setBgColor('bg-cyan-500 text-gray-200');
                break;
            }
        }
    }, [val]);

    function setNeedleVal(val: string) {
        setVal(parseFloat(val));
    }

    return(
        <div className={`relative w-1/4 h-[13rem] ${bgColor} rounded-xl drop-shadow-xl shadow-lg`}>
            <div className="text-2xl mx-auto absolute bottom-5  left-5">{state}</div>
            <select className="w-1/2 p-1 text-xl mt-3 rounded mx-auto text-black text-center absolute right-5 bottom-5"
                onChange={(event) => setNeedleVal(event.target.value)}>
                <option value="0">Tossup</option>
                <option value="-78.75">Confirmed Dem</option>
                <option value="-56.25">Very Likely Dem</option>
                <option value="-33.75">Likely Dem</option>
                <option value="-11.25">Lean Dem</option>
                <option value="11.25">Lean Rep</option>
                <option value="33.75">Likely Rep</option>
                <option value="56.25">Very Likely Rep</option>
                <option value="78.75">Confirmed Rep</option>
            </select>
            {/* <input type="number" className="w-1/3 p-1 rounded mx-auto mt-3 bottom-5 text-black absolute right-5" placeholder="-90 to 90"  /> */}
            <img src={circle} className="absolute left-0 right-0 mx-auto top-5 h-[8rem]"/>
            <img src={needle} style={style} className="absolute left-0 right-0 top-5 mx-auto h-[8rem]"/>
        </div>
    )

}

export default function Needle() {
    return(
        <div className="flex h-screen w-screen flex-col gap-4 p-4">
            <div className="flex flex-row gap-2">
                <NeedleComp state="PA" />
                <NeedleComp state="GA" />
                <NeedleComp state="MI" />
                <NeedleComp state="IA" />
            </div>
            <div className="flex flex-row gap-2">
                <NeedleComp state="NV" />
                <NeedleComp state="NC" />
                <NeedleComp state="WI" />
                <NeedleComp state="AZ" />
            </div>
            <div className="flex flex-row gap-2 justify-center">
                <NeedleComp state="White House" />
                <NeedleComp state="MT Senate" />
                <NeedleComp state="OH Senate" />
            </div>
        </div>
    )
}