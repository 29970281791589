export interface SetTemplate {
    primaryVal: string | null;
    secondaryVal: string | null;
    dateToStudy?: string;
    oldDate?: string;
}

export interface SetNameTemplate {
    setName: string;
    lastStudied?: string;
}

export enum Confidence {
    LOW, MEDIUM, HIGH
}