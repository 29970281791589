import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SetService } from "../../services/set-service";
import { SetTemplate } from "../../templates/set-template";
import DeleteIcon from "@material-ui/icons/Delete";
import { useCallback } from "react";

interface Row {
    primaryVal: string;
    secondaryVal: string;
}

enum StudyVal {
    primaryVal,
    secondaryVal
}

export default function EditSet() {
    const {setName} = useParams();
    const [setNameInput, setSetNameInput] = useState<string>('');
    const [savedSetData, setSavedSetData] = useState<SetTemplate[]>([]);
    const [rowVals, setRowVals] = useState<Row[]>([{
        primaryVal: '',
        secondaryVal: ''
    }]);
    const [renderedRows, setRenderedRows] = useState<JSX.Element[]>([]);
    
    const setService = useMemo(() => new SetService(), []);
    const navigate = useNavigate();

    useEffect(() => {
        if(setName !== undefined) {
            const set = setService.getSet(setName);
            setSavedSetData(set);
            setSetNameInput(setName);
            const tempVals: Row[] = [];
            set.forEach(item => {
                tempVals.push({primaryVal: item.primaryVal ?? '', secondaryVal: item.secondaryVal ?? ''});
            });
            setRowVals(tempVals);
        }
    }, [setName, setService]);

    useEffect(() => {
        function deleteRow(index: number) {
            const output = (rowVals.length-1 > index)
                ? [...rowVals.slice(0, index), ...rowVals.slice(index+1, rowVals.length)]
                : [...rowVals.slice(0, index)];
            setRowVals(output);
        }

        const rendered: JSX.Element[] = [];
        rowVals.forEach((row, i) => {
            rendered.push(
                <div key={i} className="flex md:flex-row sm:flex-wrap flex-col mx-auto my-2 items-center">
                    <div className="text-2xl w-16 md:mr-auto">{i+1}.</div>
                    <input type="text" defaultValue={row.primaryVal} name={"primary" + i} className="w-60 md:ml-2 ml-0 rounded bg-slate-700 text-white text-xl p-1 md:mb-0 mb-1" />
                    <input type="text" defaultValue={row.secondaryVal} name={"secondary" + i} className="w-60 md:ml-2 rounded bg-slate-700 text-white text-xl p-1" />
                    <div onClick={() => deleteRow(i)} className="cursor-pointer text-rose-600 my-auto mx-5 drop-shadow-lg">
                        <DeleteIcon />
                    </div>
                </div>
            );
        });
        rendered.push(
            <div key="button" className="my-2">
                <button type="button" onClick={() => setRowVals([...rowVals, {primaryVal: '', secondaryVal: ''}])} className="w-60 m-1 py-2 rounded-lg bg-slate-700 drop-shadow-lg">New Row</button>
            </div>
        )
        setRenderedRows(rendered);
    }, [rowVals]);

    function saveSet(passedVals: Row[]) {
        if(passedVals.length !== 0 && setNameInput !== "") {
            let success = false;
            const setData: SetTemplate[] = [];
            passedVals.forEach(vals => {
                if(vals.primaryVal === '' || vals.secondaryVal === '')
                    return;
                try {
                    const foundItem = savedSetData?.find(e =>
                        e.primaryVal === vals.primaryVal 
                        && e.secondaryVal === vals.secondaryVal);
                    if(foundItem === undefined) {
                        setData.push(
                            {
                                primaryVal: vals.primaryVal,
                                secondaryVal: vals.secondaryVal,
                                dateToStudy: setService.getDateString()
                            }
                        )
                    } else {
                        setData.push(foundItem);
                    }
                success = true;
            } catch(e) {
              console.error("Failed to save the set from text area:", e);
            }
            });
            if(success) {
                setService.saveJSON(setNameInput, setData);
                navigate("/home");
            }
        }
    }

    function onFormSubmit(event: any) {
        event.preventDefault();
        const tempVals: Row[] = [];
        for(let i = 0; i < rowVals.length; i++) {
            tempVals.push({
                primaryVal: event.target.elements['primary'+i].value,
                secondaryVal: event.target.elements['secondary'+i].value
            });
        }
        saveSet(tempVals);
    }

    function exit() {
        setSetNameInput('');
        setRowVals([{
            primaryVal: '',
            secondaryVal: ''
        }]);
        setRenderedRows([]);
        setSavedSetData([]);
        navigate('/home');
    }

    return(
        <div className="w-full h-full flex flex-col">
            <div className="flex flex-wrap text-4xl my-5 justify-center">
                Set Name:
                <input type="text" onChange={(event) => setSetNameInput(event.target.value)} value={setNameInput} className="w-72 ml-2 rounded bg-slate-700 text-white text-3xl p-1"></input>
            </div>
            <form onSubmit={onFormSubmit}>
                <div className="flex flex-col mb-10">
                    {renderedRows}
                </div>
                <div className="my-auto justify-center">
                    <button type="button" onClick={exit} className="w-60 m-1 py-2 rounded-lg bg-slate-700 drop-shadow-lg">Exit</button>
                    <button type="submit" className="w-60 m-1 py-2 rounded-lg bg-slate-700 drop-shadow-lg">Save Set</button>
                </div>
            </form>
        </div>
    );
}