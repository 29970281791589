import { ChevronLeft } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KmsVocab from "./kms-vocab/kms-vocab";

interface BlogItem {
    title: string;
    length: string;
    tag: string;
    url: string;
}

export default function Blog() {
    const {blogName} = useParams();
    const [blogPage, setBlogPage] = useState<JSX.Element>();

    const navigate = useNavigate();

    const blogList: BlogItem[] = [
        {
            title: "Korean Made Simple Vocabulary",
            length: "1 Min",
            tag: "Beginner Korean",
            url: "/blog/KMSVocab"
        }
    ];

    useEffect(() => {
        if(blogName === "KMSVocab") {
            setBlogPage(KmsVocab);
        }
    }, [blogName]);

    function renderBlogList(): JSX.Element[] {
        const renderedList: JSX.Element[] = [];
        blogList.forEach((item, i) => {
            renderedList.push(
                <div key ={i} className="flex justify-center">
                    <div onClick={() => {navigate(item.url);}} className="flex flex-row p-2 m-2 md:w-1/2 w-10/12 md:-h-20 h-24 border-slate-700 rounded drop-shadow-lg bg-gray-900 border-2 cursor-pointer">
                        <div className="flex flex-col text-left h-full">
                            <div className="text-xl font-bold my-auto">{item.title}</div>
                            <div className="text-sm text-zinc-400 my-auto ml-4 uppercase font-bold">{item.tag} | {item.length} Read</div>
                        </div>
                    </div>
                </div>
            );
        });
        return renderedList;
    }

    return (
        <div className="w-full h-full flex flex-col p-5 pt-0">
            {blogName === '' || blogName === undefined ? (
                <>
                <div className="flex flex-row text-3xl justify-center my-5">
                    Featured Posts
                </div>
                    <div>
                        {renderBlogList()}
                    </div>
                </>
            ) : (
                <>
                    <button className="absolute sm:top-24 top-16 sm:left-32 left-5 w-10 sm:w-36 py-2 rounded-lg bg-rose-600/[.75] drop-shadow-lg" onClick={() => navigate('/blog')}>
                        <div className="flex flex-row justify-center sm:mr-5">
                            <ChevronLeft></ChevronLeft>
                            <span className="sm:visible invisible w-0 sm:w-auto font-bold ml-1">Back</span>
                        </div>
                    </button>
                    {blogPage}
                </>
            )}
        </div>
    )
}