import { EmojiObjects, LocalLibrary, School } from "@material-ui/icons";
import { useState } from "react";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SetService } from "../../services/set-service";

export default function Landing() {
    const navigate = useNavigate();
    const setService = useMemo(() => new SetService(), []);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if(setService.getSetNames().length > 0)
            navigate('/home');
        else
            setIsLoading(false);
    }, [navigate, setService]);

    return(
        <div className="xl:h-screen h-full flex flex-col">
            {isLoading ? (
                <></>
            ) : (
                <>
                    <div className="xl:w-1/2 w-3/4 md:text-6xl text-3xl mx-auto md:mt-9 mt-3 font-bold">
                        Study better with the best online flashcards
                    </div>
                    <div className="xl:w-1/2 w-3/4 md:text-xl text-lg mx-auto md:mt-8 mt-4">
                        Memorize longer with spaced repetition, improve your grades, and promote active studying all in one place for free.
                    </div>
                    <button onClick={() => navigate('/home')} className="rounded hover:bg-rose-700 bg-rose-700/[.7] lg:w-1/3 md:w-1/2 w-3/4 sm:text-xl text-lg mx-auto md:mt-16 mt-10 p-1 drop-shadow-xl">
                        Start studying
                    </button>
                    <div className="flex md:flex-row flex-col w-3/4 justify-between mx-auto md:mt-16 mt-10 mb-4">
                        <div className="md:w-1/4 sm:w-1/2 w-3/4 p-1.5 flex flex-col mx-auto">
                            <div className="mx-auto text-rose-600 drop-shadow-xl md:text-6xl text-5xl">
                                <EmojiObjects fontSize="inherit" />
                            </div>
                            <div className="font-bold md:text-2xl text-xl text-center my-2">
                                Memorize longer
                            </div>
                            <div className="text-center md:text-lg text-base">
                                Our built in spaced repetition algorithm will make your flashcards stick faster and longer.
                            </div>
                        </div>
                        <div className="md:w-1/4 sm:w-1/2 w-3/4 p-1.5 flex flex-col mx-auto">
                            <div className="mx-auto text-rose-600 drop-shadow-xl md:text-6xl text-5xl">
                                <School fontSize="inherit" />
                            </div>
                            <div className="font-bold md:text-2xl text-xl text-center my-2">
                                Improve grades
                            </div>
                            <div className="text-center md:text-lg text-base">
                                Xué uses methods researchers have found to be up to 2x more effective than their alternatives.
                            </div>
                        </div>
                        <div className="md:w-1/4 sm:w-1/2 w-3/4 p-1.5 flex flex-col mx-auto">
                            <div className="mx-auto text-rose-600 drop-shadow-xl md:text-6xl text-5xl">
                                <LocalLibrary fontSize="inherit" />
                            </div>
                            <div className="font-bold md:text-2xl text-xl text-center my-2">
                                Study actively
                            </div>
                            <div className="text-center md:text-lg text-base">
                                Our flashcards are designed to promote active studying which creates lasting memorization.
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );

}