import { SetService } from '../../../services/set-service';
import { SetTemplate } from '../../../templates/set-template';
import vocabList from './vocab.json';

export default function KmsVocab() {
    const setName = "KMS1 Vocab";

    function getVocab(): SetTemplate[] {
        const formatList: SetTemplate[] = [];
        try{
            vocabList.text.split(';').forEach(row => {
                let items = row.split("~~");
                formatList.push({
                    primaryVal: items[0],
                    secondaryVal: items[1]
                });
            });
        } catch(e) {
            console.log("Could not generate formated list of vocab words", e);
        }
        return formatList;
    }

    const AddVocab = () => {
        const setService = new SetService();
        const vocab = getVocab();
        const existingSet = setService.getSetNames().find(s => s.setName === setName);
        if(existingSet === undefined) {
            console.log("Saving KMS1 Vocab Study Set");
            setService.saveJSON(setName, vocab);
        }
    }

    return(
        <div className="mx-auto md:w-5/12 w-10/12">
            <div className="text-3xl font-bold mt-5">
                Korean Made Simple Vocabulary
            </div>
            <div className="font-bold mt-0 text-zinc-400">
                BEGINNER KOREAN | 1 MIN READ
            </div>
            <div className="text-xl mt-5 text-left">
                Korean Made Simple 1 is a beginner Korean book that aims to help readers achieve a moderate conversational and written proficency.
            </div>
            <div className="text-xl mt-2 text-left">
                In particular, it contains hundreds of extremely common Korean vocabulary that is vital in one's first step towards fluency.
            </div>
            <div className="text-xl mt-2 text-left">
                Click below to add Korean Made Simple 1 vocab to your study sets and begin learning with Xue's cutting edge flashcards, learning, and memorization technology. Then, navigate back to the home page to start your Korean mastery journey.
            </div>
            <div className="my-auto justify-center mt-5">
                <button type="button" onClick={AddVocab} className="w-60 m-1 py-2 rounded-lg bg-slate-700 drop-shadow-lg">Add KMS 1 Study Set</button>
            </div>
        </div>
    );

}